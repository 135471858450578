<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <c-table
          ref="table"
          title="설비 목록"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="editable"
          noDataLabel="점검계획할 설비를 추가하세요. 저장할 시 점검결과를 등록 할 수 있습니다."
          selection="multiple"
          @callbackProxy="callbackProxy"
          @rowClick="rowClick"
          rowKey="equipmentCd"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable && !isSave" :showLoading="false" label="추가" icon="add" @btnClicked="add" />
              <c-btn v-if="editable && !isSave && grid.data.length > 0" :showLoading="false"  label="삭제" icon="remove" @btnClicked="remove" />
              <c-btn v-if="editable && !isSave" label="저장" icon="save" @btnClicked="save"/>
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div v-if="isSave" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card title="설비별 결과상세">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn :editable="editable" label="설비별 점검결과 저장" icon="save" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-card class="cardClassDetailForm" title="설비별 결과 기본정보" bgClass="">
                <template slot="card-detail">
                  <div class="col-12">
                    <c-label-text title="사업장/점검부서" :value="result.plantName +'/' + result.deptNm"></c-label-text>
                  </div>
                  <div class="col-12">
                    <c-label-text title="설비유형/설비코드/설비명" :value="result.equipmentType + '/' + result.equipmentCd + '/' + result.equipmentNm"></c-label-text>
                  </div>
                  <div class="col-12">
                    <c-label-text title="점검유형" :value="result.safFacilityNm"></c-label-text>
                  </div>
                  <div class="col-12">
                    <c-label-text title="점검자" :value="result.chkUserNm"></c-label-text>
                  </div>
                  <div class="col-12">
                    <c-label-text title="점검예정일" :value="result.chkSchYmd"></c-label-text>
                  </div>
                   <div class="col-12">
                  </div>
                   <div class="col-12">
                    <c-datepicker
                      label="점검완료일"
                      name="chkSchCompleYmd"
                      v-model="result.chkSchCompleYmd">
                    </c-datepicker>
                  </div>
                  <div class="col-12">
                    <c-select
                      :comboItems="chkResultItems"
                      :editable="editable"
                      type="edit"
                      itemText="codeName"
                      itemValue="code"
                      name="chkResult"
                      label="점검결과"
                      v-model="result.chkResult"
                    ></c-select>
                  </div>
                  <div class="col-12">
                    <c-select
                      :comboItems="chkRsltYnItems"
                      :editable="editable"
                      type="edit"
                      itemText="codeName"
                      itemValue="code"
                      name="chkRsltYn"
                      label="점검완료여부"
                      v-model="result.chkRsltYn"
                    ></c-select>
                  </div>
                  <div class="col-12">
                    <c-textarea
                      :editable="editable"
                      label="점검결과요약"
                      name="chkRsltSmry"
                      v-model="result.chkRsltSmry">
                    </c-textarea>
                  </div>
                </template>
              </c-card>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
              <div class="col-12">
                <c-table
                  ref="table"
                  title="설비별 점검항목"
                  :columns="grid2.columns"
                  :gridHeight="grid2.height"
                  :data="grid2.data"
                  :filtering="false"
                  :columnSetting="false"
                  :usePaging="false"
                  :hideBottom="true"
                  noDataLabel="설비에 대한 점검항목이 없습니다."
                  :editable="editable&&!popupParam.disabled"
                  rowKey="checkItemSeq"
                  selection="multiple"
                >
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn v-if="editable" label="추가" :showLoading="false"  icon="add" @btnClicked="addItem" />
                      <c-btn v-if="editable && grid2.data.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeItem" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
            </div>
          </template>
        </c-card>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'equipment-plan',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        title: '',
        disabled: false,
        multiple: false,
      }),
    },
  },
  data() {
    return {
      editable: true,
      result: {
        plantName: '사업장1',
        deptNm: '안전환경팀',
        safFacilityNm: '자체(일상)점검',
        safFacilityCd: '1',
        equipmentType: '집진설비',
        equipmentNm: '집진설비 설비명',
        equipmentCd: 'F3-G938',
        chkSchYmd: '2021-07-24',
        chkUserId: '1',
        chkUserNm: '홍씨',
        chkSchCompleYmd: '2021-08-06',
        remark: '집진설비에 대한 비고',
        chkRsltSmry: '점검결과요약'
        // plantCd: '',
        // plantName: '사업장1',
        // equipmentType: '',
        // equipmentCd: '',
        // equipmentNm: '',
        // chkResult: '',
        // chkRsltYn: '',
        // chkSchYmd: '',
        // chkRsltSmry: '',
        // chkSchCompleYmd: '',
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: true
          },
          {
            name: 'deptNm',
            field: 'deptNm',
            label: '점검부서',
            align: 'center',
            sortable: true
          },
          {
            name: 'equipmentType',
            field: 'equipmentType',
            label: '설비유형',
            align: 'center',
            sortable: true
          },
          {
            name: 'equipmentCd',
            field: 'equipmentCd',
            label: '설비코드',
            align: 'center',
            sortable: true
          },
          {
            name: 'safFacilityCd',
            field: 'safFacilityCd',
            label: '점검유형',
            align: 'center',
            style: 'width:100px',
            type: 'select',
            comboItems: [
              { code: '1', codeName: '자체(일상)정비' },
              { code: '2', codeName: '법정(정기)정비' },
            ],
            sortable: false,
          },
          {
            name: 'chkUserNm',
            field: 'chkUserNm',
            label: '점검예정자',
            align: 'center',
            style: 'width:120px',
            type: 'user',
            userId: 'chkUserId',
            sortable: false,
          },
          {
            name: 'chkSchYmd',
            field: 'chkSchYmd',
            label: '점검예정일',
            align: 'center',
            style: 'width:200px',
            type: 'date',
            sortable: true,
            disableTarget: 'chngRsltYn',
            disableCon: 'O',
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'left',
            style: 'width:200px',
            type: 'textarea',
            sortable: false,
          },
        ],
        data: [],
        height: '300px'
      },
      grid2: {
        columns: [
          {
            name: 'checkItemNm',
            field: 'checkItemNm',
            label: '점검항목',
            align: 'center',
            sortable: false
          },
          {
            name: 'checkResult',
            field: 'checkResult',
            label: '점검결과',
            align: 'center',
            type: 'text',
            sortable: false
          },
          {
            name: 'inspMethod',
            field: 'inspMethod',
            label: '검사방법',
            align: 'center',
            type: 'text',
            sortable: false
          },
          {
            name: 'criteria',
            field: 'criteria',
            label: '판정기준',
            align: 'center',
            style: 'width:100px',
            type: 'select',
            comboItems: [
              { code: '1', codeName: '적합' },
              { code: '2', codeName: '부적합' },
            ],
            sortable: false
          },
          {
            name: 'safetyInfo',
            field: 'safetyInfo',
            label: '점검시 안전사항',
            align: 'center',
            type: 'textarea',
            sortable: false
          },
        ],
        data: [],
        height: '650px'
      },
      title: '',
      chkResultItems: [],
      chkRsltYnItems: [],
      disabled: false,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isSave: false,
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      this.chkResultItems = [
        { code: '1', codeName: '적합' },
        { code: '2', codeName: '부적합' },
      ];
      this.chkRsltYnItems = [
        { code: 'Y', codeName: '완료' },
        { code: 'Y', codeName: '미완료' },
      ]
      // list setting
      this.title = this.popupParam.title;
      this.getList();
    },
    getList() {
      this.grid.data = [
        { 
          plantName: '사업장1',
          deptNm: '안전환경팀',
          safFacilityNm: '자체(일상)점검',
          safFacilityCd: '1',
          equipmentType: '집진설비',
          equipmentNm: '집진설비 설비명',
          equipmentCd: 'F3-G938',
          chkSchYmd: '2021-07-24',
          chngNum: 'MOC01',
          chkUserId: '1',
          chkUserNm: '홍씨',
          remark: '집진설비에 대한 비고',
          chkRsltSmry: '점검결과요약'
        },
      ];
      this.grid2.data = [
        {
          checkItemSeq: 1,
          checkItemNm: '1. 공장운전을위한 일반사항 점검', // 점검항목
          checkResult: '일반사항 점검완료', // 점검결과
          inspMethod: '체크리스트 체크', // 검사방법
          criteria: '1', // 판정기준
          safetyInfo: '일반점검시 안전사항', // 점검시 안전사항
        }, 
        {
          checkItemSeq: 2,
          checkItemNm: '2. 시운전 준비 (전력/계장)', // 점검항목
          checkResult: '시운전 준비완료', // 점검결과
          inspMethod: '체크리스트 체크', // 검사방법
          criteria: '2', // 판정기준
          safetyInfo: '시운전 점검시 안전사항', // 점검시 안전사항
        }
      ]
    },
    add() {
      this.popupOptions.title = '설비 검색'; // 설비 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/fim/equipmentPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipmentPopup;
    },
    closeEquipmentPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.grid.data, {
            equipmentCd: _item.equipCd,
          });
          if (index === -1) {
            this.grid.data.splice(0, 0, {
              plantName: _item.plantName,
              deptNm: _item.deptNm,  
              chkUserNm: '', 
              chkUserId: '',
              safFacilityNm: null, 
              safFacilityCd: null, 
              equipmentType: _item.equipType, 
              equipmentCd: _item.equipCd, 
              equipmentNm: _item.equipNm,
              chkSchYmd: '', 
              remark: '', 
            })
          }
        })
      }
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.grid.data = this.$_.reject(this.grid.data, { equipmentCd: item.equipmentCd })
        })
      }
    },
    callbackProxy(data, props, col) {
      if (col.name === 'chngNum') {
        this.grid.data[props.rowIndex]['chngNum'] = data.chngNum;
      }
    },
    rowClick(row) {
      this.result = this.$_.clone(row);
    },
    addItem() {
      this.grid2.data.splice(0, 0, {
        checkItemSeq: 0,
        checkItemNm: '', // 점검항목
        checkResult: '', // 점검결과
        inspMethod: '', // 검사방법
        criteria: '', // 판정기준
        safetyInfo: '', // 점검시 안전사항
      })
    },
    removeItem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.grid2.data = this.$_.reject(this.grid2.data, item)
        })
      }
    },
    save() {
      var flag = false;
      if (this.grid.data.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '설비를 추가하세요.', // 설비를 추가하세요.
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      this.$_.forEach(this.grid.data, item => {
        if (!item.safFacilityCd) {
          window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: 
            // item.equipmentNm + 
            '점검유형을 추가하세요.', // 설비유형을 추가하세요.
            type: 'warning', // success / info / warning / error
          });
          flag = true;
          return;
        }
        if (!item.chkUserNm) {
          window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: 
            // item.equipmentNm + 
            '점검예정자를 선택하세요.', // 점검예정자를 선택하세요
            type: 'warning', // success / info / warning / error
          });
          flag = true;
          return;
        }
        if (!item.chkSchYmd) {
          window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: 
            // item.equipmentNm + 
            '점검예정일을 선택하세요.', // 점검예정일을 선택하세요.
            type: 'warning', // success / info / warning / error
          });
          flag = true;
          return;
        }
      })

      if (!flag)
      this.isSave = true;
    },
  }
};
</script>
